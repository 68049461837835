<template>
    <div class="container page">
        <van-nav-bar :title="$t('打赏记录')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <template #right>
                <van-image class="menu-item-icon" src="img/mine/menu.png" @click="showDateFilter()">
                    <template v-slot:loading>
                        <van-loading type="spinner" />
                    </template>
                </van-image>
            </template>
        </van-nav-bar>

        <van-popup v-model="showDatePopup" position="right" class="content">
            <div class="timeBox">
                <div class="title">
                    <div class="titleText">{{$t('时间筛选')}}</div>
                </div>
                <div class="datetimepickerBtnBox">
                    <div class="datetimepickerBtn" @click="showStartDatePicker = true">
                        <div class="datetimepickerBtnText">{{ startDate }}</div>
                    </div>
                    <div class="datetimepickerBtnDashes">~</div>
                    <div class="datetimepickerBtn" @click="showEndDatePicker = true">
                        <div class="datetimepickerBtnText">{{ endDate }}</div>
                    </div>
                </div>
            </div>
            <div class="lotteryBox">
                <div class="title">
                    <div class="titleText">{{$t('全部任务')}}</div>
                </div>
                <van-list class="lotteryWrapper">
                    <van-cell v-for="lottery in lotteries" :key="lottery.id" @click="selectGame(lottery.id)"
                        class="lotteryItem" :class="lottery.selected ? 'itemLightStyle' : 'itemDefaultStyle'">
                        <span class="lotteryItemspan">{{ lottery.name }}</span>
                    </van-cell>
                </van-list>
            </div>
            <div class="bottomBox">
                <div class="resetBtn" @click="resetFilter">
                    <div class="bottomBtnText">{{$t('重置')}}</div>
                </div>
                <div class="confirmBtn" @click="filterResults">
                    <div class="bottomBtnText">{{$t('确定')}}</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="showStartDatePicker" position="bottom">
            <van-datetime-picker type="date" v-model="pickedStartDate" @confirm="onConfirmStartDate" />
        </van-popup>
        <van-popup v-model="showEndDatePicker" position="bottom">
            <van-datetime-picker type="date" v-model="pickedEndDate" @confirm="onConfirmEndDate" />
        </van-popup>
        <van-tabs v-model="currentTab" animated sticky line-width="100px" :swipeable="true" @change="tabChanged">
            <van-tab :title="$t('全部')"></van-tab>
            <van-tab :title="$t('盈利')"></van-tab>
            <van-tab :title="$t('盈亏')"></van-tab>
            <van-tab :title="$t('未结算')"></van-tab>
        </van-tabs>
        <div class="main">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-empty v-if="list.length === 0" :description="$t('数据为空')" />
                <div v-else class="item_list" v-for="(v,key) in list" :key="key">
                    <div class="lottery_info">
                        <van-image class="cover" :src="'img/photo_2022-11-12_21-53-12.jpg'">
                            <template v-slot:loading>
                                <van-loading type="spinner" />
                            </template>
                        </van-image>
                        <span class="period-number">{{v.expect}}</span>
                        <span class="period-number">{{v.name}}</span>
                    </div>
                    <div class="recent">
                        <div class="kuaisan-ball left">
                            <span
                                class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("玫瑰") : $t("糖心")}}</span>
                            <span
                                class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("烟花") : $t("火箭")}}</span>
                        </div>
                    </div>
                    <div class="topInfo">
                        <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
                        <span v-else>{{v.status_text}}</span>
                        <span>{{$t('打赏积分')}}：{{v.money}}</span>
                    </div>
                    <div class="topInfo">
                        <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
                        <span v-else>{{v.win_text}}</span>
                        <span>{{$t('金额')}}：{{v.profit}}</span>
                    </div>
                    <div class="topInfo">
                        <span>{{$t('任务类型')}}：</span>
                        <span>{{v.type}}</span>
                    </div>
                    <div class="time">
                        <span>{{$t('下单时间')}}：{{v.create_time}}</span>
                    </div>
                    <div class="time">
                        <span>{{$t('结算时间')}}：{{v.update_time}}</span>
                    </div>
                </div>
            </van-pull-refresh>
            <div class="jiazai">
                <button @click="loadMore" v-if="!noMoreData">{{$t('加载更多')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            const today = this.formatDate(new Date());
            return {
                isLoading: false,
                list: [],
                page: 1,
                noMoreData: false, // 用于判断是否还有更多数据
                is_win: null,
                sendIsWin: false, // 新的标志
                currentTab: 0,
                showDatePopup: false,
                showStartDatePicker: false,
                showEndDatePicker: false,
                startDate: today,
                endDate: today,
                pickedStartDate: new Date(), // 初始化开始日期
                pickedEndDate: new Date(), // 初始化结束日期
                hasDateSelected: false,
                lotteries: [{
                        id: 1,
                        name: 'Lottery 1',
                        selected: false
                    },
                    {
                        id: 2,
                        name: 'Lottery 2',
                        selected: false
                    },
                    // ... 其他lotteries数据
                ],
            };
        },
        methods: {
            back() {
                return window.history.back();
            },
            showDateFilter() {
                this.showDatePopup = true;
            },
            formatDate(d) {
                let month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            resetFilter() {
                // 重置所有筛选条件
                this.pickedStartDate = null;
                this.pickedEndDate = null;
                this.lotteries.forEach(lottery => {
                    lottery.selected = false;
                });
            },

            filterResults() {
                // 用户确实选择了日期
                if (this.pickedStartDate && this.pickedEndDate) {
                    this.startDate = this.formatDate(this.pickedStartDate);
                    this.endDate = this.formatDate(this.pickedEndDate);
                    this.hasDateSelected = true;
                } else {
                    const today = this.formatDate(new Date());
                    this.startDate = today;
                    this.endDate = today;
                    this.hasDateSelected = false;
                }
                this.list = [];
                this.page = 1;
                this.getUserGameList();
            },
            onConfirm() {
                const formattedDate = this.formatDate(this.pickedDate);
                console.log(formattedDate); // 这将输出如 "2023-08-25" 的日期
            },
            onConfirmStartDate() {
                this.startDate = this.formatDate(this.pickedStartDate);
                this.hasDateSelected = true; // 用户选择了日期
                this.showStartDatePicker = false;
            },
            onConfirmEndDate() {
                this.endDate = this.formatDate(this.pickedEndDate);
                this.hasDateSelected = true; // 用户选择了日期
                this.showEndDatePicker = false;
            },
            selectGame(lotteryId) {
                this.lotteries.forEach(lottery => {
                    lottery.selected = false;
                });
                const selectedLottery = this.lotteries.find(lottery => lottery.id === lotteryId);
                if (selectedLottery) {
                    selectedLottery.selected = true;
                }
                this.$forceUpdate();
            },
            getUserGameList() {
                let requestData = {
                    page: this.page,
                    lang: this.lang,
                    startDate: this.startDate,
                    endDate: this.endDate
                };

                // 使用默认值，如果没有特定选择，则从很早的日期开始到今天
                requestData.startDate = this.hasDateSelected ? this.startDate : '1900-01-01';
                requestData.endDate = this.hasDateSelected ? this.endDate : this.formatDate(new Date());

                if (this.is_win !== null) {
                    requestData.is_win = this.is_win;
                }
                const selectedLottery = this.lotteries.find(lottery => lottery.selected);
                if (selectedLottery) {
                    requestData.lotteryId = selectedLottery.id;
                }

                this.$http({
                    method: 'post',
                    url: 'user_get_game_list',
                    data: requestData
                }).then(res => {
                    if (res.code === 200) {
                        if (res.data && res.data.length > 0) {
                            this.list = [...this.list, ...res.data];
                            this.page++;
                        }
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                });
            },
            getGameItem() {
                this.$http({
                    method: "post",
                    url: "lottery_hot2",
                }).then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.lotteries = res.data;
                    }
                });
            },
            tabChanged(index) {
                switch (index) {
                    case 0:
                        this.is_win = null; // 表示全部
                        this.sendIsWin = false;
                        break;
                    case 1:
                        this.is_win = 1; // 表示盈利
                        break;
                    case 2:
                        this.is_win = 2; // 表示盈亏
                        break;
                    case 3:
                        this.is_win = 0; // 表示未结算
                        break;
                }
                this.page = 1; // 可能需要重置页码为1
                this.list = []; // 可能需要清空之前的数据
                this.getUserGameList();
            },
            resetAndFetch() {
                this.page = 1;
                this.list = [];
                this.noMoreData = false;
                this.getUserGameList();
            },
            onRefresh() {
                this.page = 1; // 刷新时重置页码
                this.list = []; // 清空当前列表
                this.getUserGameList(); // 重新获取第一页数据
                setTimeout(() => {
                    this.$toast(this.$t('刷新成功'));
                    this.isLoading = false;
                }, 500);
            },


            loadMore() { // 加载下一页数据
                if (!this.noMoreData) { // 如果还有更多数据
                    this.getUserGameList();
                }
            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                });
            } else {
                this.getUserGameList();
                this.getGameItem()
            }
        }
    };
</script>


<style lang='less' scoped>
    @import "../../assets/css/base.css";

    ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
        color: #fff;
        font-size: 35px;
    }

    ::v-deep .van-loading__text {
        color: #fff;
        font-size: 35px;
    }

    ::v-deep .van-tabs__line {
        background-color: #f3c7a2;
    }

    ::v-deep .van-tabs--line .van-tabs__wrap {
        height: 100px;
    }

    ::v-deep .van-tabs__wrap--scrollable .van-tab {
        padding: 0 23px;
    }

    ::v-deep .van-tabs__nav {
        background-color: #16151b;
    }

    ::v-deep .van-tab {
        font-size: 30px;
        line-height: 100px;
        font-weight: bold;
        color: #fff;
    }
    .menu-item-icon{
        width: 55px;
    }
    .content {
        position: fixed;
        right: 0;
        padding-left: 0.26667rem;
        padding-right: 0.26667rem;
        height: 100%;
        width: 80vmin;
        background-color: #282828;
        color: #fff;
        z-index: 10;
        overflow-y: auto;
        box-shadow: 0.8rem 0 0.8rem 0.8rem hsla(0, 0%, 88.2%, .2);
        -webkit-overflow-scrolling: touch;
    }

    .container .content .timeBox {
        padding-bottom: 30px;
    }

    .container .content .timeBox .title {
        display: flex;
        height: 100px;
        justify-content: flex-start;
        align-items: center;
    }

    .container .content .timeBox .title .titleText {
        font-size: 36px;
    }

    .container .content .timeBox .datetimepickerBtnBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .container .content .timeBox .datetimepickerBtnBox .datetimepickerBtn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.02667rem solid #fff;
        border-radius: 10px;
        height: 70px;
    }

    .container .content .timeBox .datetimepickerBtnBox .datetimepickerBtn .datetimepickerBtnText {
        font-size: 32px;
    }

    .container .content .timeBox .datetimepickerBtnBox .datetimepickerBtnDashes {
        display: flex;
        align-items: center;
        font-size: .42667rem;
        margin-left: 2%;
        margin-right: 2%;
    }

    .container .content .lotteryBox {
        border-top: 0.02667rem solid #f2f2f5;
    }

    .container .content .lotteryBox .title {
        display: flex;
        height: 100px;
        justify-content: flex-start;
        align-items: center;
    }

    .container .content .lotteryBox .title .titleText {
        font-size: 36px;
    }

    .container .content .lotteryBox .lotteryWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }

    .container .content .lotteryBox .lotteryWrapper .lotteryItem {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 48%;
        text-align: center;
        height: 70px;
        margin-bottom: 2%;
        margin-right: 2%;
    }

    .container .content .lotteryBox .lotteryWrapper .itemDefaultStyle {
        color: #979799;
        border: 0.02667rem solid #979799;
        background-color: #282828;
    }

    .lotteryItemspan {
        font-size: 28px;
        color: #979799 !important;
    }

    .van-cell__value--alone {
        text-align: center;
    }

    .itemLightStyle {
        color: #282828;
        background: #e6b98a;
    }

    .container .content .bottomBox {
        position: absolute;
        bottom: 0.53333rem;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container .main {
        position: relative;
        overflow: auto;
        background-color: #16151b;
        height: 100%;
        padding: 0 10px;
    }

    .van-cell::after {
        border-bottom: none;
    }

    .container .content .bottomBox .resetBtn {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        height: 80px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .container .content .bottomBox .confirmBtn .bottomBtnText {
        font-size: .42667rem;
        font-weight: 500;
    }

    .container .content .bottomBox .confirmBtn {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        height: 80px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .container .content .bottomBox .confirmBtn .bottomBtnText {
        font-size: .42667rem;
        font-weight: 500;
    }

    .container .content .bottomBox .resetBtn .bottomBtnText {
        color: #e6b98a;
        font-size: 36px;
    }

    .container .content .bottomBox .resetBtn {
        border: 0.02667rem solid #e6b98a;
    }

    .container .content .bottomBox .confirmBtn {
        background-color: #e6b98a;
    }

    .container .content .bottomBox .confirmBtn .bottomBtnText {
        color: #282828;
        font-size: 36px;
    }

    .item_list {
        padding: 15px 15px;
        margin: 30px 10px;
        background: #212121;
        border-radius: 10px;
        line-height: 60px;
    }

    .item_list .topInfo span {
        flex: 1;
        font-size: 35px;
        font-weight: 700;
        color: #ff253f;
    }

    .item_list .time span {
        flex: 1;
        font-size: 25px;
        font-weight: 500;
        color: #fff;
    }

    .item_list .topInfo span:last-child {
        float: right;
    }

    .item_list .desc span {
        font-size: 25px;
        font-weight: 700;
        color: #9b9b9b;
    }

    .item_list .cover {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .item_list .period-number {
        margin-left: 50px;
        margin-right: 10px;
        height: 50px;
        line-height: 60px;
        font-size: 30px;
        font-weight: 700;
        color: #fff;
    }

    .item_list .lottery_info {
        display: flex;
    }

    .recent {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .kuaisan-ball .left {
        justify-content: flex-start;
    }

    .kuaisan-ball {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .kuaisan-ball .res-img {
        width: 70px;
        height: 70px;
        margin-right: 30px;
    }

    .kuaisan-ball .res-des {
        font-weight: 700;
        text-align: center;
        color: #fff;
    }

    .kuaisan-ball .res-des.middle {
        width: 30%;
        font-size: 35px;
    }

    .jiazai {
        height: 50px;
        margin: 30px auto;
        margin-top: 0;
        width: 100%;
        text-align: center;
    }

    .jiazai button {
        width: 60%;
        margin: 0 auto;
        background: linear-gradient(#fde3ca, #e7b486);
        border-radius: 20px;
        border: none;
        color: #333;
    }
</style>