<template>
    <div class="container page">
        <van-nav-bar :title="$t('填写收款卡')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="main-box">
            <div class="label" style="text-align: center;">{{ $t("请输入您的收款卡信息") }}</div>
            <van-cell-group>
                <van-field v-model="bank" :label="$t('银行名称')" readonly :placeholder="$t('请选择银行')"
                    @click="showSelectBanks()" />
                
                <div class="input-div one" :class="{ focus: isFocusedCpf || cpf.length > 0 }">
                    <div class="i">
                        <i class="iconfont icon-bank"></i>
                    </div>
                    <div class="div">
                        <h5>{{ $t("CPF") }}</h5>
                        <input type="text" v-model="cpf" id="cpf" class="input" @focus="handleFocusCpf"
                            @blur="handleBlurCpf">
                    </div>
                </div>
                <div class="input-div one" :class="{ focus: isFocusedBankid || bankid.length > 0 }">
                    <div class="i">
                        <i class="iconfont icon-bank"></i>
                    </div>
                    <div class="div">
                        <h5>{{ $t("银行卡号") }}</h5>
                        <input type="text" v-model="bankid" id="bankid" class="input" @focus="handleFocusBankid"
                            @blur="handleBlurBankid">
                    </div>
                </div>
                <div class="input-div one" :class="{ focus: isFocusedUsername || username.length > 0 }">
                    <div class="i">
                        <i class="iconfont icon-user"></i>
                    </div>
                    <div class="div">
                        <h5>{{ $t("真实姓名") }}</h5>
                        <input type="text" v-model="username" id="username" class="input" @focus="handleFocusUsername"
                            @blur="handleBlurUsername">
                    </div>
                </div>
            </van-cell-group>
            <p>{{$t('尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款')}}</p>
        </div>
        <van-button class="bindCard" type="default" @click="bindCard()">{{$t('确认绑卡')}}</van-button>
        <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }">
            <van-picker 
                show-toolbar 
                :columns="banks" 
                @confirm="onConfirm" 
                @cancel="onCancel" 
                :confirm-button-text="$t('确定')" 
                :cancel-button-text="$t('取消')"
            />
        </van-popup>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                banks: [],
                showBank: false,
                userInfo: {},
                bankid: "",
                username: "",
                bank: "",
                cpf: "",
                bank_code: "",
                isFocusedUsername: false,
                isFocusedBankid: false,
                isFocusedCpf: false,
            };
        },
        methods: {
            back() {
                return window.history.back();
            },
            handleFocusUsername() {
                this.isFocusedUsername = true;
            },
            handleBlurUsername() {
                if (this.username.length === 0) {
                    this.isFocusedUsername = false;
                }
            },
            handleFocusBankid() {
                this.isFocusedBankid = true;
            },
            handleBlurBankid() {
                if (this.bankid.length === 0) {
                    this.isFocusedBankid = false;
                }
            },
            handleFocusCpf() {
                this.isFocusedCpf = true;
            },
            handleBlurCpf() {
                if (this.bankid.length === 0) {
                    this.isFocusedCpf = false;
                }
            },
            moveContent() {
                if (this.bankid === '') {
                    this.$refs.inputContent.style.top = '-20px'; /* 移动内容到输入框上方 */
                }
            },
            checkContent() {
                if (this.bankid === '') {
                    this.$refs.inputContent.style.top = '0'; /* 内容返回输入框内 */
                }
            },
            bindCard() {

                if (this.userInfo.bankid) {
                    this.$toast(this.$t("请输入银行卡号"));
                    return true;
                }
                if (this.bank === "" || this.bank === null || this.bank === undefined) {
                    this.$toast.fail(this.$t("请选择银行"));
                    return false;
                }

                this.$http({
                    method: 'post',
                    data: {
                        bankid: this.bankid,
                        bank: this.bank,
                        bank_code: this.bank_code,
                        username: this.username,
                        cpf: this.cpf,
                    },
                    url: 'user_set_bank'
                }).then(res => {
                    if (res.code === 200) {
                        this.$router.push({
                            path: '/Mine'
                        })
                        this.$toast(res.msg);
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            showSelectBanks() {
                this.showBank = true;
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                        this.userInfo = res.data;
                        this.name = res.data.name;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            getBankList() {
                this.$http({
                    method: 'get',
                    url: 'sys_get_banks'
                }).then(res => {
                    if (res.code === 200) {
                        this.banks = res.data;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            onConfirm(value) {
                this.bank = value.text
                this.bank_code = value.value
                this.showBank = false;
            },
            onCancel() {
                this.showBank = false;
            },
            getUserBankInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_bank) {
                            this.is_bind = true;
                        } else {
                            this.is_bind = false;
                        }
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                })
            } else {
                this.getUserInfo();
                this.getBankList();
                this.getUserBankInfo();
            }
        }
    };
</script>
<style>
    .input-div {
        position: relative;
        display: grid;
        width: 90%;
        grid-template-columns: 7% 93%;
        margin: 25px auto;
        padding: 5px 0;
        border-bottom: 2px solid #e1e1e1;
    }

    .input-div.one {
        margin-top: 0;
    }

    .i {
        color: #e1e1e1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .i i {
        transition: .3s;
    }

    .input-div>div {
        position: relative;
        height: 9vw;
        margin-top: 20px;
    }

    .input-div>div>h5 {
        position: absolute;
        left: 4%;
        top: 50%;
        margin: 0;
        transform: translateY(-50%);
        color: #e1e1e1;
        font-size: 30px;
        transition: .3s;
    }

    .input-div:before,
    .input-div:after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 0%;
        height: 2px;
        background: linear-gradient(90deg, #c24491, #775fd9);
        transition: .4s;
    }

    .input-div:before {
        right: 50%;
    }

    .input-div:after {
        left: 50%;
    }

    .input-div.focus:before,
    .input-div.focus:after {
        width: 50%;
    }

    .input-div.focus>div>h5 {
        top: -0.5vw;
        font-size: 24px;
    }

    .input-div.focus>.i>i {
        color: #775fd9;
    }

    .input-div>div>input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: none;
        padding: 0.5rem 0.7rem;
        font-size: 1.2rem;
        color: #fff;
        font-family: 'poppins', sans-serif;
    }

    .input-div.pass {
        margin-bottom: 4px;
    }
</style>
<style lang='less' scoped>
    @import "../../assets/css/iconfont.css";
    @import "../../assets/css/base.css";

    .van-cell {
        font-size: 32px;
        line-height: 80px;
    }

    .van-hairline--bottom::after {
        border-bottom-width: 3px;
    }

    .bankbox {
        padding: 15px;
        color: #000;
        background-color: #212121;
    }

    .bankbox .title {
        padding: 8px 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
    }

    .main-box {
        background: #212121;

    }

    .main-box .label {
        padding: 20px;
        font-size: 35px;
        color: #e1e1e1;
    }

    ::v-deep .van-picker__toolbar {
        height: 50px;
    }

    ::v-deep .van-picker__cancel,
    .van-picker__confirm {
        padding: 0 20px;
        font-size: 20px;
    }

    ::v-deep .van-picker-column {
        font-size: 40px;
    }
    ::v-deep .van-cell-group {
        background: none;
    }
    ::v-deep .van-cell {
        background: none;
    }
    ::v-deep .van-field__control {
        color: #fff;
    }

    .main-box p {
        padding: 0 20px;
        font-size: 30px;
        color: #ee0a24;
    }

    .bindCard {
        margin: 20px 30px 0;
        height: 80px;
        line-height: 1.22667rem;
        border-radius: 50px;
        color: #fff;
        font-size: 30px;
        font-weight: bolder;
        border: none;
        background: linear-gradient(#fde3ca,#e7b486);
    }
</style>