import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import common from './common/function';
import 'swiper/swiper-bundle.css';
import store from './store';
import VueI18n from 'vue-i18n';

import 'video.js/dist/video-js.css';

Vue.prototype.$http = http;
Vue.prototype.common = common;
Vue.prototype.serviceUrl = "https://0813kj.jinbiaoyanshi.com/";
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(Vant);
Vue.use(VueI18n); // 引入 VueI18n

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh_hk",
  messages: {
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'zh_hk': require('./assets/languages/zh_hk.json'),
    'en_us': require('./assets/languages/en_us.json'),
    // 其他语言的翻译数据
  }
});
console.log("Current locale in interceptor:", Vue.prototype.$i18n ? Vue.prototype.$i18n.locale : "Not set");

new Vue({
  store,
  router,
  i18n, // 使用 i18n
  render: h => h(App),
}).$mount('#app');