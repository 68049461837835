<template>
    <div class="container page">
        <div class="header">
            <van-nav-bar :title="$t('充值请求成功')" class="nav-bar">
                <template #left>
                    <van-icon name="arrow-left" color="#fff" @click="back()" />
                </template>
            </van-nav-bar>
        </div>
        <div class="main">
            <div class="success">
                <div class="van-radio-group" role="radiogroup" style="padding:20px">
                    <div role="radio" class="van-radio van-radio--label-disabled" tabindex="0" aria-checked="true">
                        <div class="van-radio__icon van-radio__icon--round van-radio__icon--checked"><i
                                class="van-badge__wrapper van-icon van-icon-success"
                                style="border-color: rgb(59, 189, 66); background-color: rgb(59, 189, 66);"><!----><!----><!----></i>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <p>{{$t('存款申请已完成，您的存款申请已成功提交')}}</p>
                    <span style="fonts-size:14px;color:#979799">{{$t('存款积分')}}：</span><span
                        style="fonts-size:14px;color:#979799">{{money}}</span>
                </div>
            </div>
            <div class="description">
                <van-icon name="service" style="padding:20px" />
                <div class="info">{{$t('温馨提示')}}：
                <div class="title">{{$t('存款请求成功后，您的余额将在一分钟内更新，请稍后查看')}}
                    </div><span>{{$t('如果余额没有更新请联系')}}</span><a
                        @click="gokefu">{{$t('在线客服')}}</a></div>
            </div>
        </div>
        <button class="btn" @click="fre">{{$t('确定')}}</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            };
        },
        created() {
            this.money = this.$route.params.money
        },
        methods: {
            gokefu() {
                const service = this.$store.getters.getBaseInfo;
                console.log(service)
                if (service.iskefu == 1) {
                    console.log('ssss')
                    window.location.href = service.kefu
                }
                // this.$router.push("ServicePage");
            },
            back() {
                return window.history.back();
            },
            fre() {
                this.$router.push({
                    name: 'mine',
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
    .main {
        padding: 10px;
        background-color: #212121;
        color: #fff;
        padding: 40px 0;

        .success {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 2px solid #fff;
            padding-bottom: 30px;
        }

        .description {
            padding-top: 0.37333rem;
            display: flex;
            flex-direction: row;

            .info {
                color: #e6b98a;

                span {
                    color: #fff;
                }

                a {
                    color: #f33d3d;
                    text-decoration: underline;
                }

                .title {
                    font-size: 12px;
                    color: #fff;
                }

            }
        }
    }

    .btn {
        width: 100%;
        margin-top: 40px;
        height: 100px;
        border-radius: 80px;
        outline: 0;
        border: 0;
        color: #fff;
        background: linear-gradient(#fde3ca, #e7b486);
    }
</style>