<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('充值记录')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
			</van-nav-bar>
		</div>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('listview.pulling_text')"
				:loosing-text="$t('listview.loosing_text')" :loading-text="$t('listview.loading_text')"
				:success-text="$t('listview.success_text')">
				<van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
				<div v-else class="item_list" v-for="(v,key) in list" :key="key">
					<div class="topInfo">
						<span v-if="v.money < 0" style="color: #07c160">提现成功</span>
						<span v-else-if="v.type === 2" style="color: #07c160">Ưu đãi gửi tiền</span>
						<span v-else-if="v.status === 2" style="color: #07c160">{{v.status_text}}</span>
						<span v-else-if="v.status === 4" style="color: #07c160">{{v.status_text}}</span>
						<span v-else>{{v.status_text}}</span>
						<span v-if="v.status === 2" style="color: #07c160">{{$t("金额")}}：{{v.money}}</span>
						<span v-else>{{$t("金额")}}：{{v.money}}</span>
					</div>
					<div class="desc">
						<span>{{$t("说明")}}：{{v.desc}}</span>
					</div>
					<div class="time">
						<span>{{$t("提现时间")}}：{{v.create_time}}</span>
					</div>
					<div class="time">
						<span>{{$t("审核时间")}}：{{v.update_time}}</span>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				list: []
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast(this.$t("reservation.refresh"));
					this.isLoading = false;
				}, 500);
			},
			getUserWithdrawList() {
				this.$http({
					method: 'post',
					url: 'getUserRechargeList'
				}).then(res => {
					if (res.code === 200) {
						this.list = res.data;
						console.log(res.data)
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserWithdrawList();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #fff;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #fff;
		font-size: 35px;
	}

	.container .main {
		position: relative;
		overflow: auto;
		background-color: #16151b;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #212121;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 35px;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #fff;
	}

	.item_list .topInfo span:last-child {
		float: right;
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}
</style>