<template>
    <div class="convention-hall page">
        <van-nav-bar class="nav-bar" :title="$t('Sflirt')">
            <template #right>
                <div class="language" @click="navigateToLanguage">
                    <img :src="currentImage" />
                    <span>{{ currentLanguage }}</span>
                </div>
            </template>
        </van-nav-bar>
        <div class="convention-item">
            <van-swipe :autoplay="3000" lazy-render class="my-swipe" :show-indicators="false">
                <van-swipe-item v-for="image in images" :key="image"><img :src="image"
                        class="banner_img" /></van-swipe-item>
            </van-swipe>
            <div class="describe">
                <ul>
                    <li>
                        <div>{{$t('Sflirt')}}</div>
                        <div>{{$t('华人第一福利品牌')}}</div>
                    </li>
                    <li>
                        <span>{{$t('为了会员权益，网站只展示')}}</span>
                        <b style="font-size: 16px; color: #f8c693">1%</b>
                        <span>{{$t('资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待')}}</span>
                    </li>
                </ul>
                <div>{{$t('本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧')}}</div>
            </div>

            <van-tabs animated sticky line-width="100px" :swipeable="true">
                <van-tab :title="$t('Sflirt')">
                    <!-- <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div> -->
                    <!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
                    <div class="xuanfei" v-for="(val, key) in xuanfeilist" :key="key" @click="xfts(val.id)">
                        <div class="left-div">
                            <div>ID:{{ val.itemid }}</div>
                            <div>
                                <ul>
                                    <li>{{$t('昵称')}}: {{ val.nickname }}</li>
                                    <li>{{$t('类型')}}：{{ val.typelabel }}</li>
                                    <li>{{$t('所在地区')}}：{{ val.region }}</li>
                                    <li>{{$t('服务项目: 激活可见')}}</li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
                                            alt="" />
                                        <span>{{ val.views }}</span>
                                    </li>
                                    <li>
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
                                            alt="" />
                                        <span>{{ val.collection }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="right-div"><img :src="domain + val.flagimg" alt="" /></div>
                        <i class="sign-i">{{$t('已认证')}}</i>
                    </div>
                </van-tab>
                <van-tab :title="$t('实时配对')">
                    <div class="scroll-cmp">
                        <van-swipe :height="52" vertical :autoplay="2000" :duration="4000" :show-indicators="false">
                            <van-swipe-item v-for="(val, key) in peiduilist" :key="key">
                                <div>
                                    <span>{{ val.name }}</span>
                                    <span>{{ val.tel }}</span>
                                </div>
                                <div>
                                    <span>{{ val.title }}</span>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </van-tab>
                <van-tab :title="$t('约炮心得')">
                    <div class="scroll-cmp">
                        <van-swipe :height="52" vertical :autoplay="2000" :duration="4000" :show-indicators="false">
                            <van-swipe-item v-for="(val, key) in yuepaolist" :key="key">
                                <div>
                                    <span>{{ val.name }}</span>
                                    <span>{{ val.tel }}</span>
                                </div>
                                <div>
                                    <span>{{ val.title }}</span>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </van-tab>
                <van-tab :title="$t('服务条款')">
                    <div class="card">{{$t('本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。')}}</div>
                    <div class="rig-box">
                        <p class="rig-title">{{$t('有哪些资源?')}}</p>
                        <p class="rig-content">{{$t('网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到')}}</p>
                        <p class="rig-title">{{$t('服务范围?')}}</p>
                        <p class="rig-content">{{$t('同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。')}}</p>
                        <p class="rig-content">{{$t('本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。')}}
                        </p>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            currentLanguage() {
                const languages = {
                    'en_us': 'English',
                    'zh_cn': '简体中文',
                    'zh_hk': '繁體中文'
                };
                return languages[this.lang] || '繁體中文'; // 默认为English
            },
            currentImage() {
                return require(`../images/language/${this.lang}.png`);
            }
        },
        data() {
            return {
                lang: localStorage.getItem('lang') || 'zh_hk',
                domain: this.serviceUrl,
                addlist: [{
                        0: '北京',
                        1: '上海',
                        2: '广州',
                        3: '深圳'
                    },
                    {
                        0: '南京',
                        1: '长沙',
                        2: '重庆',
                        3: '西安'
                    }
                ],
                images: ['img/home/1.jpg', 'img/home/2.jpg', 'img/home/3.jpg', 'img/home/4.jpg'],
                xuanfeilist: [],
                peiduilist: [{
                        name: this.$t('步先生'),
                        tel: '(852-6*****66)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****42)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-6*****55)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****12)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****92)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****41)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****66)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****74)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****75)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****21)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****67)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****03)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****39)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****80)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****76)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****27)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****61)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****23)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-6*****70)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****15)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****65)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****62)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****51)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****85)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****95)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****14)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****95)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****34)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****91)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-9*****34)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****94)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****06)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****86)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****73)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****80)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****34)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****43)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****05)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****19)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-6*****30)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****56)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****36)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****82)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****85)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****09)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-6*****77)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****07)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****34)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-9*****49)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****57)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****59)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****21)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****27)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****96)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****86)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-6*****81)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****28)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****30)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-6*****90)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****78)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****98)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****05)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****70)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****83)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****06)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****20)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****00)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****48)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-6*****32)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****02)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****77)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****79)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****13)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****00)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****60)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****07)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****43)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****67)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****97)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****93)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****79)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****48)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****31)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-6*****93)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****82)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****96)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****36)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****33)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****14)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-6*****67)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-6*****35)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****91)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****66)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****54)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-9*****79)',
                        title: this.$t('正在服务中......')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****30)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****04)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-6*****47)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****50)',
                        title: this.$t('通过本站已预约......')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****20)',
                        title: this.$t('通过本站已预约......')
                    },

                ],
                yuepaolist: [{
                        name: this.$t('赵先生'),
                        tel: '(852-6*****85)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****17)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****07)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****12)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****39)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****41)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****65)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****68)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****57)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****34)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-6*****30)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****43)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****64)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****65)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-9*****13)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-6*****92)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-6*****44)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****24)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-6*****79)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-6*****13)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-9*****76)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-6*****88)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-6*****18)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****28)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****35)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****11)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-6*****45)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****70)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****09)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****65)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****13)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****51)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****60)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****53)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****68)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****49)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****93)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****67)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****60)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****96)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****36)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-9*****03)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-6*****47)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****83)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-6*****73)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****71)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****63)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****15)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****05)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-9*****01)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-9*****03)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****21)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****32)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****75)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****30)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****19)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****63)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****81)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****40)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-6*****29)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****39)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****22)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-6*****97)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****48)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-9*****13)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-9*****61)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****29)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-6*****13)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('周先生'),
                        tel: '(852-9*****49)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****00)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('钱先生'),
                        tel: '(852-9*****58)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****47)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****47)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****45)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****02)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-6*****16)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****61)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-9*****05)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****44)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('张先生'),
                        tel: '(852-9*****29)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-6*****78)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('李先生'),
                        tel: '(852-6*****11)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-6*****84)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****79)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-6*****06)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****17)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('郑先生'),
                        tel: '(852-9*****17)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('陈先生'),
                        tel: '(852-6*****10)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('吕先生'),
                        tel: '(852-6*****76)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-6*****18)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****95)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('卫先生'),
                        tel: '(852-9*****19)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('楚先生'),
                        tel: '(852-9*****74)',
                        title: this.$t('朋友介绍的，妞可以年轻漂亮，服务够莞式。')
                    },
                    {
                        name: this.$t('王先生'),
                        tel: '(852-9*****83)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                    {
                        name: this.$t('赵先生'),
                        tel: '(852-6*****46)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('步先生'),
                        tel: '(852-9*****93)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-9*****24)',
                        title: this.$t('颜值很高，服务很专业，还会热舞...')
                    },
                    {
                        name: this.$t('冯先生'),
                        tel: '(852-9*****63)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('吴先生'),
                        tel: '(852-6*****46)',
                        title: this.$t('联系约好时间叫我去她家操她，皮肤还是很紧致')
                    },
                    {
                        name: this.$t('孙先生'),
                        tel: '(852-9*****37)',
                        title: this.$t('床上满分 可一字马 性格超好 氵超多')
                    },
                ]
            };
        },
        methods: {
            navigateToLanguage() {
                if (this.$route.path !== '/language') {
                    this.$router.push('/language'); // 导航到语言选择路由
                }
            },
            addgo(data) {
                if (!localStorage.getItem('token')) {
                    this.$router.push({
                        path: '/Login'
                    });
                } else {
                    this.$router.push({
                        path: '/list?id=' + data.id + '&name=' + data.name
                    });
                }
            },
            getAddress() {
                this.$http({
                    method: 'post',
                    url: 'indexlist'
                }).then(res => {
                    this.xuanfeilist = res.data;
                });
            },
            xfts(id) {
                this.$router.push({
                    path: '/Detail?id=' + id
                });
            }
        },
        /*beforeRouteUpdate (to, from, next) {
		console.log(to);
        if (to.name != 'home' && to.name != 'login' && !localStorage.getItem("token")) {
			next("login");
			return;
        }
		next();
	},*/
        created() {
            this.getAddress();
        }
    };
</script>

<style lang="less" scoped>
    .language {
        position: absolute;
        top: 18px;
        right: 0;
        height: 60px;
        color: #fff;
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            margin: 0 3px;
        }
    }

    ::v-deep .van-nav-bar__right {
        width: 100%;
    }

    .page {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        background-color: #16151b;
        /*height: 100%;*/
    }

    .nav-bar {
        // background: linear-gradient(90deg, #7e5678, #e6c3a1);
        height: 100px;
        background-color: #16151b;
    }

    .van-nav-bar {
        line-height: 50px;
    }

    ::v-deep .van-nav-bar__title {
        max-width: 60%;
        margin: 0 auto;
        font-size: 35px;
        color: #f3c7a2;
        font-weight: bold;
    }

    ::v-deep .van-nav-bar__content {
        height: 100px;
    }

    .van-sidebar {
        width: 180px;
    }

    .my-swipe {
        border-radius: 10px;
        height: 310px;
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        // line-height: 510px;
        text-align: center;
        background-color: #39a9ed;
    }

    .convention-hall {
        // display: flex;
        // flex-direction: column;
        // bottom: 20px;
        // background: #f2f2f5;
        // height: 100%;
    }

    .convention-item {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 3.5625rem;
    }

    .banner_img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
    }

    ::v-deep .van-tab {
        font-size: 30px;
        line-height: 100px;
        font-weight: bold;
        color: #fff;
    }

    ::v-deep .van-tabs__line {
        background-color: #f3c7a2;
    }

    ::v-deep .van-tabs--line .van-tabs__wrap {
        height: 100px;
    }

    ::v-deep .van-tabs__wrap--scrollable .van-tab {
        padding: 0 23px;
    }

    .describe {
        width: 100%;
        min-height: 9, 875rem;
        border-radius: 0.625rem;
        border: 1px solid #766350;
        margin: 0.625rem 0;
        background: linear-gradient(120deg, #212121, #313131);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem;
        position: relative;
        overflow: hidden;
    }

    .describe>ul {
        display: flex;
        align-items: flex-start;
        padding: 20px 0;
    }

    .describe>ul>li:first-child {
        width: 7.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .describe>ul li {
        height: 100%;
    }

    .describe>div {
        height: 3.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #e6b98a;
        line-height: 1.5rem;
        font-size: 0.75rem;
    }

    .describe>ul>li:first-child>div:first-child {
        font-size: 1rem;
        font-weight: 700;
    }

    .describe>ul>li:first-child div {
        color: #f3c7a2;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .describe>ul>li:first-child>div:nth-child(2) {
        font-size: 0.8125rem;
    }

    .describe>ul>li:nth-child(2) {
        padding: 0 0 0 0.625rem;
        width: calc(100% - 7.6rem);
        font-size: 0.625rem;
        line-height: 1rem;
        color: #fff;
        text-align: center;
        border-left: 0.1875rem solid #e6b98a;
    }

    .card {
        background-color: #8a637d;
        padding: 0.625rem;
        width: 95%;
        color: white;
        font-size: 0.8125rem;
        margin: 0.625rem auto;
        border-radius: 0.375rem;
    }

    ::v-deep .van-row--flex {
        height: 80px;
        line-height: 80px;
    }

    ::v-deep .van-tabs__nav {
        background-color: #16151b;
    }

    .rig-box {
        width: 95%;
        margin: 0.625rem auto;
    }

    .rig-title {
        color: #0bdab0;
        font-size: 1.125rem;
    }

    .rig-content {
        font-size: 20px;
        // margin-top: 10px;
        color: #fff;
    }

    .address {
        width: 90%;
        margin: 0 auto;
    }

    .xuanfei {
        width: 100%;
        height: 9.75rem;
        border-radius: 0.625rem;
        border: 0.0625rem solid #766350;
        margin: 0.625rem 0 0.625rem 0;
        background: linear-gradient(120deg, #212121, #313131);
        display: flex;
        justify-content: space-between;
        padding: 0.625rem;
        position: relative;
        overflow: hidden;
    }

    .xuanfei .left-div {
        width: 12.9375rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .xuanfei .right-div {
        width: 7.75rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .xuanfei .sign-i {
        position: absolute;
        right: -40px;
        top: 10px;
        background: #ff6056;
        font-size: 15px;
        padding: 10px 45px;
        font-style: normal;
        transform: rotate(45deg);
        color: #fff;
    }

    .xuanfei .left-div>div:first-child {
        background: linear-gradient(90deg, #efcba7, #dcab76);
        color: #8d5825;
        height: 1.25rem;
        padding: 0 1.25rem;
        border-radius: 0.625rem;
        line-height: 1.25rem;
        text-align: center;
        font-size: 1rem;
    }

    .xuanfei .left-div>div:nth-child(2) {
        margin: 0.625rem 0;
        padding-left: 0.625rem;
    }

    .xuanfei .left-div>div:nth-child(2) ul li {
        text-align: left;
        font-size: 24px;
        color: #fff;
        width: 12.9375rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .xuanfei .left-div>div:nth-child(3) ul {
        display: flex;
    }

    .xuanfei .left-div>div:nth-child(3) ul>li:first-child {
        margin-right: 0.625rem;
    }

    .xuanfei .left-div>div:nth-child(3) ul li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .xuanfei .left-div>div:nth-child(3) ul li img {
        height: 0.8125rem;
        margin-right: 0.125rem;
    }

    .xuanfei .left-div>div:nth-child(3) ul li span {
        font-size: 0.8125rem;
        color: #696969;
    }

    .xuanfei .right-div img {
        width: 6.5rem;
        height: 6.5rem;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
    }

    .scroll-cmp {
        width: 100%;
        height: 20.9375rem;
        border-radius: 0.625rem;
        border: 0.0625rem solid #766350;
        margin: 0.625rem 0 6.25rem 0;
        background: linear-gradient(120deg, #212121, #313131);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.625rem;
        position: relative;
        overflow: hidden;
    }

    .scroll-cmp .van-swipe-item {
        height: 1.125rem;
    }

    .scroll-cmp .van-swipe-item div:first-child span {
        color: #e6b98a;
        font-size: 0.9375rem;
    }

    .scroll-cmp .van-swipe-item div:nth-child(2) span {
        color: #fff;
        font-size: 0.7125rem;
    }
</style>