<template>
    <div class="container page">
        <van-nav-bar :title="$t('提现中心')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <template #right>
                <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{$t('记录')}}</span>
            </template>
        </van-nav-bar>
        <div class="main">
            <div class="withdrawMoney">
                <div class="add-card" @click="toBindCard()" v-if="!is_bind">
                    <van-icon name="plus" />
                    <span>{{$t("添加银行卡")}}</span>
                </div>
                <div class="bank" v-else>
                    <div v-if="!bankInfo.length" class="add-card" @click="toBindCard()">
                        <van-icon name="plus" />
                        <span>{{$t("添加银行卡")}}</span>
                    </div>
                    <!-- 显示银行卡下拉选择列表 -->
                    <select v-if="bankInfo.length" v-model="selectedBank" @change="onBankChange">
                        <option v-for="(bank, index) in bankInfo" :key="index" :value="bank.bankid">
                            {{ bank.username }} - {{ bank.bankinfo }} - {{ bank.bankid }} -{{ formatBankId(bank.cpf) }}
                        </option>
                    </select>
                </div>
                <span>{{$t('兑换积分')}}</span>
                <div class="money">
                    <div class="moneyNumber">
                        <van-field v-model="withdraw_money" oninput="value=value.replace(/[^\d]/g,'')" type="number" />
                    </div>
                    <span class="all" @click="allMoeny()">{{$t('全部')}}</span>
                </div>
                <div class="information">
                    <div class="description">
                        <van-popover v-model="showPopover" trigger="click">
                            <div class="popover-body" style="padding: 10px;">
                                <p>1.{{$t('单笔限额：最低')}}{{this.withdrawRole.min}}{{$t('元')}}，{{$t('最高')}}{{this.withdrawRole.max}}{{$t('元')}}
                                </p>
                                <p>2.{{$t('提现次数：一天最高提现')}}{{this.withdrawRole.num}}{{$t('次')}}</p>
                                <p>3.{{$t('到账时间：一般到账时间在5分钟左右，最快2分钟内到账')}}</p>
                            </div>
                            <template #reference @click="withdrawInfo()">
                                <van-icon name="info-o" />
                                {{$t('限额说明')}}
                            </template>
                        </van-popover>
                    </div>

                    <div class="balance">
                        <span>{{$t('余额')}}：</span>
                        <span class="number">{{ this.userInfo.money }}{{$t('元')}}</span>
                    </div>
                </div>
            </div>
            <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{$t('马上兑换')}}</van-button>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showPopover: false,
                withdraw_money: "",
                userInfo: {},
                is_bind: false,
                bankInfo: {},
                cpf:{},
                withdrawRole: {},
                selectedBank: null,
            };
        },

        methods: {
            formatBankId(bankid) {
                if (bankid && bankid.length >= 8) {
                    return bankid.substring(0, 1) + "***" + bankid.substring(bankid.length - 4);
                }
                return bankid;
            },
            onBankChange() {
                const selectedBankObj = this.bankInfo.find(bank => bank.bankid === this.selectedBank);
                console.log(selectedBankObj); // 这会打印出整个银行卡对象，其中包括ID、银行名称等其他信息
            },
            back() {
                return window.history.back();
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                        this.userInfo = res.data;
                        this.name = res.data.name;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },

            getUserWithdrawRole() {
                this.$http({
                    method: 'get',
                    url: 'user_get_withdraw_role'
                }).then(res => {
                    if (res.code === 200) {
                        this.withdrawRole = res.data;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            getUserBankInfo() {
                return new Promise((resolve, reject) => {
                    this.$http({
                        method: 'get',
                        url: 'user_get_bank'
                    }).then(res => {
                        console.log(res)
                        if (res.code === 200) {
                            if (res.data.is_bank) {
                                this.is_bind = true;
                                this.bankInfo = res.data.info;
                                resolve(res.data.info); // 当请求成功时，调用 resolve
                            } else {
                                this.is_bind = false;
                                resolve(null); // 如果没有绑定银行卡，也调用 resolve，但传递 null
                            }
                        } else if (res.code === 401) {
                            this.$toast(res.msg);
                            reject(res.msg); // 当请求失败时，调用 reject
                        }
                    })
                });
            },
            toBindCard() {
                if (!this.userInfo.paypassword) {
                    this.$router.push("SetPayPassword");
                    this.$toast(this.$t("setting.set_pwd_bank"));
                    return true;
                } else {
                    this.$router.push({
                        path: '/BindCard'
                    })
                }
            },
            allMoeny() {
                this.withdraw_money = Math.floor(this.userInfo.money);
            },
            doWithdraw() {
                if (this.withdraw_money <= 0) {
                    this.$toast(this.$t("请填写正确的金额"));
                    return false;
                } else {
                    const selectedBankObj = this.bankInfo.find(bank => bank.bankid === this.selectedBank);

                    if (!selectedBankObj) {
                        this.$toast("请选择正确的银行卡");
                        return false;
                    }

                    this.$http({
                        method: 'post',
                        data: {
                            bankid: this.selectedBank,
                            bank_bind_bankinfo: selectedBankObj.bankinfo,
                            cpf: selectedBankObj.cpf,
                            bank_bind_username: selectedBankObj.username, // 添加选中的银行卡在bank_bind表中的ID
                            money: this.withdraw_money
                        },
                        url: 'user_set_withdraw'
                    }).then(res => {
                        if (res.code === 200) {
                            location.reload();
                            this.$toast(res.msg);
                            this.getUserInfo();
                            this.getUserWithdrawRole();
                        } else if (res.code === 401) {
                            this.$toast(res.msg);
                        }
                    });
                }
            },
            withdrawInfo() {
                this.showPopover = true;
            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                });
            } else {
                this.getUserInfo();
                this.getUserWithdrawRole();
                this.getUserBankInfo().then(data => {
                    if (data && this.bankInfo.length) {
                        this.selectedBank = this.bankInfo[0].bankid;
                    }
                });
            }
        },
    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";
    ::v-deep .van-cell{
        background-color: #555353;
        border-radius: 20px;
        color: #fff;
    }
    ::v-deep .van-field__body input{
        color: #fff;
        padding: 0 10px;
    }
    .van-cell {
        font-size: 35px;
        line-height: 80px;
    }

    .container p {
        padding: 0 15px;
        margin-top: 15px;
        font-size: 30px;
        color: #dc2037;
    }

    .container .main {
        display: flex;
        flex-direction: column;
        background-color: #16151b;
        height: calc(100% - 50px);
        position: relative;
    }

    .container .main .withdrawMoney {
        display: flex;
        flex-direction: column;
        color: #fff;
        padding: 0 20px;
        white-space: nowrap;
        font-size: 35px;
        background-color: #212121;
    }

    .container .main .withdrawMoney span {
        padding: 10px 0;
    }

    .container .main .withdrawMoney .money {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .container .main .withdrawMoney .money .moneyNumber {
        font-size: 50px;
        display: flex;
        flex-direction: row;
    }

    .container .main .withdrawMoney span {
        padding: 10px 0;
    }

    .container .main .withdrawMoney .money .all {
        color: #f3d3b8;
    }

    .container .main .withdrawMoney .money .moneyNumber .van-cell {
        font-size: 50px;
        padding: 0 !important;
    }

    .container .main .withdrawMoney .information {
        padding-bottom: 30px;
    }

    .container .main .withdrawMoney .information .description {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 0;
    }

    .container .main .withdrawMoney span {
        padding: 10px 0;
    }

    .container .main .withdrawMoney .information .balance .number {
        color: #f3d3b8;
        font-weight: 600;
    }

    .withdraw_btn {
        margin: 30px 50px 0;
        height: 80px;
        line-height: 1.22667rem;
        border-radius: 50px;
        color: #fff;
        font-size: 30px;
        font-weight: bolder;
        border: none;
        background: linear-gradient(#fde3ca,#e7b486);
    }

    .bank {
        display: inline-block;
        height: 7.667vw;
        width: 85%;
        margin: 2.333vw auto;
    }

    .bank select {
        display: inline-block;
        height: 7.667vw;
        width: 100%;
        background-color: #212121;
        text-align: center;
        border-radius: 10px;
        color: #fff;
    }
</style>