<template>
    <div class="container page">
        <van-nav-bar :title="$t('收款卡信息')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="wrapper">
            <div class="add-card" @click="toBindCard()" v-if="!is_bind">
                <van-icon name="plus" />
                <span>{{$t('添加收款卡')}}</span>
            </div>
            <div class="bank" v-if="is_bind">
                <div class="add-card" @click="toBindCard()">
                    <van-icon name="plus" />
                    <span>{{$t("添加收款卡")}}</span>
                </div>
                <div class="add-card1" v-for="(card, index) in bankInfo" :key="index">
                    <div class="add-card">
                        <span>{{card.username || userInfo.name}}</span>
                    </div>
                    <div class="add-card">
                        <span>{{ formatBankId(card.cpf) }}</span>
                    </div>
                    <div class="add-card">
                        <span>{{ card.bankid }}</span>
                    </div>
                    <div class="add-card">
                        <span>{{card.bankinfo}}</span>
                    </div>
                </div>
            </div>
            <div class="tips">{{$t('提示:请绑定大型商业银行,如需修改,请您联系在线客服')}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                is_bind: false,
                bankInfo: {},
                userInfo: {},
                card: {}
            };
        },
        computed: {
            formattedBankId() {
                if (this.card && this.card.bankid && this.card.bankid.length >= 8) {
                    return this.card.bankid.slice(0, 4) + '****' + this.card.bankid.slice(-4);
                }
                return this.card && this.card.bankid; // 安全地返回原始值
            }
        },
        methods: {
            formatBankId(bankid) {
                if (bankid && bankid.length >= 8) {
                    return bankid.substring(0,1) + "***" + bankid.substring(bankid.length - 4);
                }
                return bankid;
            },
            back() {
                return window.history.back();
            },
            getUserBankInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_bank) {
                            this.is_bind = true;
                            this.bankInfo = res.data.info;
                        } else {
                            this.is_bind = false;
                        }
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                        this.userInfo = res.data;
                        this.name = res.data.name;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            toBindCard() {
                /* if (!this.userInfo.name) {
                  this.$router.push("Setname");
                  this.$toast(this.$t("请设置姓名后再绑定银行卡"));
                  return true;
                }else */
                if (!this.userInfo.paypassword) {
                    this.$router.push("SetPayPassword");
                    this.$toast(this.$t("请设置提现密码后再绑定银行卡"));
                    return true;
                } else {
                    this.$router.push({
                        path: '/BindCard'
                    })
                }
            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                })
            } else {
                this.getUserInfo();
                this.getUserBankInfo();
            }
        }
    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";

    .add-card1 {
        display: flex;
        background-color: #212121;
        align-items: center;
        justify-content: space-evenly;
    }

    .van-cell {
        font-size: 35px;
        line-height: 45px;
    }

    .container p {
        padding: 0 15px;
        margin-top: 15px;
        font-size: 30px;
        color: #dc2037;
    }

    .manage-card .wrapper {
        height: calc(100% - 10px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .wrapper .add-card {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #212121;
        height: 100px;
    }

    .wrapper .add-card span {
        margin-left: 10px;
        font-size: 40px;
    }

    .wrapper .tips {
        margin: 15px 15px;
        font-size: 30px;
        color: #e1e1e1;
    }

    .wrapper .bank .info {
        margin-left: 20px;
        flex: 1;
        color: #000;
    }

    .wrapper .bank .info .row-content {
        margin: 30px 0;
        line-height: 20px;
        font-size: 30px;
    }
</style>