<template>
    <div class="container page">
        <div class="header">
            <van-nav-bar :title="$t('充值')" class="nav-bar">
                <template #left>
                    <van-icon name="arrow-left" color="#fff" @click="back()" />
                </template>
            </van-nav-bar>
        </div>
        <div class="main">
            <div class="wrapper"><span class="title">{{$t('请输入您的转账信息')}}</span>
                <p class="desc">{{$t('这是您需要转账的银行账户信息')}}</p>
                <div class="item"><span class="label">{{$t('银行名称')}}:</span>
                    <p>{{userInfo.yhk_type}}</p>
                    <button class="copy-button" @click="copyText(userInfo.yhk_type)">{{$t('复制')}}</button>
                </div>
                <div class="item"><span class="label">{{$t('收款人姓名')}}:</span>
                    <p>{{userInfo.yhk_name}}</p>
                    <button class="copy-button" @click="copyText(userInfo.yhk_name)">{{$t('复制')}}</button>
                </div>
                <div class="item"><span class="label">{{$t('银行账户')}}:</span>
                    <p>{{userInfo.yhk}}</p>
                    <button class="copy-button" @click="copyText(userInfo.yhk)">{{$t('复制')}}</button>
                </div>
                <div class="inputMoney"><span class="label">{{$t('所充值积分')}}:</span><a class="money">{{money}}</a></div>
                <div class="label up span-icon-div"><span>{{$t('请上传您的凭证')}}:</span><i
                        class="van-badge__wrapper zhIcon zhIcon-ashbin ashbin-icon"
                        style="font-size: 20px;"><!----><!----><!----></i></div>
                <van-uploader v-model="fileList" :max-count="1" image-fit="contain" :after-read="afterRead"
                    style="margin: 0 auto " :before-delete="deleImg">

                    <img style="width:280px;height:200px" src="../images/lever/img.png" alt="" />

                </van-uploader>
                <button class="btn" @click="btn">{{$t('提交')}}</button>
            </div>
        </div>


    </div>

</template>

<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                fileList: [
                    // 	{
                    // 	url: 'https://img01.yzcdn.cn/vant/leaf.jpg'
                    // },
                ],
                userInfo: {
                    yhk_type: '',
                    yhk_name: '',
                    yhk: ''
                },
                money: 0,
                img: '',
                res: {}
            }
        },
        methods: {
            deleImg() {
                this.img = ''
                this.fileList = []
            },
            back() {
                return window.history.back();
            },
            btn() {
                if (this.img == '') return
                this.$http({
                    method: "post",
                    data: {
                        img: this.img,
                        money: this.money
                    },
                    url: "recharge1"
                }).then((res) => {
                    if (res.code === 200) {
                        // this.$toast(res.msg);
                        this.$router.push({
                            name: 'rechargeSuccess',
                            params: {
                                money: this.money
                            }
                        })
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                });
            },
            copyText(text) {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);

                this.$toast(this.$t('复制成功')); // 使用你的提示方式
            },
            afterRead(result) {
                let formData = new FormData();
                formData.append("file", result.file);
                axios.post('https://0813kj.jinbiaoyanshi.com/api/system/doupload', formData, {
                    'Content-Type': 'multipart/form-data'
                }).then(res => {
                    console.log(res);

                    if (res.data.code === 200) {
                        this.$toast(this.$t('上传成功'));
                        this.img = res.data.data
                    } else if (res.data.code === 401) {
                        this.$toast(res.data.msg);
                    }
                })
            },
        },
        created() {
            this.money = this.$route.params.money
            this.$http({
                method: 'get',
                url: 'base_info',
            }).then(res => {
                if (res.code === 200) {
                    this.userInfo = res.data;
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep .van-uploader__preview-image {
        width: 280px;
        height: 200px;
    }
    .copy-button{
        color: #333;
    }
    .main {
        font-size: 12px;
        background-color: #212121;

        .up {
            margin-bottom: 20px;
        }

        .preview-cover {
            position: absolute;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 4px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            background: rgba(255, 255, 255, 255.3);
        }

        .wrapper {
            padding: 32px 32px;
            display: flex;
            flex-direction: column;
            color: #fff;
        }

        .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0;
            color: #fff;
        }

        .inputMoney {
            padding: 20px 0;
            color: #fff;
            border-top: 2px solid #cacaca;
        }

        .btn {
            margin-top: 22px;
            height: 100px;
            border-radius: 80px;
            outline: 0;
            border: 0;
            color: #fff;
            background: linear-gradient(#fde3ca, #e7b486);
        }

        .copy {
            border: 1px solid #c24491;
            border-radius: 8px;
            color: #000;
            background-color: #f2f2f5;
            outline: none;
        }
    }
</style>