<template>
    <div class="container page">
        <van-nav-bar :title="$t('防骗指南')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#e6b98a" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="scroll-div">
            <ul>
                <li><img src="/img/home/fangpian-1.0b1b930b.png" alt="" /></li>
                    <img src="/img/home/fangpian-3.6f7acad2.png" class="img" alt="" />
                    <p>1.{{$t('本平台所有资源均为实名制，女孩和男人皆是VIP会员请双方彼此需一定的尊重，不要出言不逊没有素质，一经发现平台将坚决取消约炮资格，如情节严重将报警依法处置请谨记！')}}</p>
                    <p>2.{{$t('本平台小姐姐不会收取任何费用，只需完成平台三次打赏数据获得约炮名额即可享受同城约炮资源，本平台可免费提供各式情趣酒店，其余花销需自理哦！')}}</p>
                    <p>3.{{$t('妹子已经到你预约的地点，要求【收款才能上楼】均为骗子无疑，请立即向平台联系接待或者客服举报！')}}</p>
                <li>

                    <div>
                        <p class="warn-p">
                            {{$t('友情提醒：专属接待员，数据专员，指导者，禁止双方私自添加彼此联系方式，以免造成不必要的麻烦，上班时间均为13:00~01:00其余时间内均不受理业务。若有在除外时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！')}}
                        </p>
                        <p>4.{{$t('凡是要求下载其他软件的都是骗子，请立即截图向平台专属接待或者客服举报！届时平台将会依照情节给以奖励.')}}</p>
                        <p>5.{{$t('本平台对每一位VIP会员寻欢经历负责，遇到任何问题请及时向平台专属接待员或者客服反馈举报，官方核实后退还解锁消耗！')}}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        methods: {
            back() {
                return window.history.back();
            }
        }
    };
</script>

<style lang="less" scoped>
    @import '../../assets/css/base.css';

    ::v-deep .van-nav-bar__title {
        color: #e6b98a;
    }
    .img{
        display: inline;
        float: left;
        margin-left: -50px;
        width: 350px;
        height: 400px;
    }
    .scroll-div {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: #fff;
        background-image: 
                linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
                url('/img/home/fanghudun.png');
        background-repeat: no-repeat;
        background-position: 250px 700px;  // 定义背景图片的位置
        background-size: 480px 540px; // 根据需要可以调整，如果你希望背景图片始终适应容器的大小
    }

    .scroll-div ul {
        width: 95%;
        margin: 0 auto;
        height: 100%;
        color: #000;
    }

    .scroll-div ul li:first-child {
        height: 6.5rem;
    }

    .scroll-div ul li:first-child img {
        width: 90%;
        height: 6.5rem;
        -o-object-fit: contain;
        object-fit: contain;
        display: block;
        margin: 0 auto;
    }

    .scroll-div ul li:nth-child(2) {
        word-break: break-all;

        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.625rem;
    }

    .scroll-div ul p {
        color: #000;
        font-size: 0.875rem;
        margin-bottom: 0.3125rem;
        font-weight: bold;
    }

    .scroll-div ul li:nth-child(2) img {
        width: 10.1875rem;
        height: 13rem;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .scroll-div ul li:nth-child(3) {
        height: 14.25rem;
        word-break: break-all;

        align-items: flex-start;
        justify-content: space-between;
    }

    .scroll-div ul li:nth-child(3) img {
        width: 6.5rem;
        height: 16rem;
        float: right;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .scroll-div ul li:nth-child(3) div {
        width: 80%;
    }

    .scroll-div ul p.warn-p {
        color: #000;
    }
</style>