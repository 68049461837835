<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
			</van-nav-bar>
			
			<div class="info">
				<p class="title">
					{{ $t("recharge.curr_balance") }}({{ $t("reservation.unit") }})
				</p>
				<p class="value">{{ this.balance }}</p>
			</div>
			<div class="content recharge">
				<van-form @submit="onSubmit">
					<div class="form-item">
						<div class="form-item-title">{{ $t("recharge.input_money") }}</div>
						<div style="height: 65px">
							<van-field v-model="money" name="money" label="K"
								:placeholder="$t('recharge.input_money')" />
						</div>
					</div>
					<div class="form-item">
						<div class="form-item-title">{{ $t("recharge.pay_way") }}</div>
						<div>
							<van-radio-group v-model="pay_way">
								<van-radio name="Zalo">zalo</van-radio>
								<van-radio name="Momo">momo</van-radio>
								<van-radio name="Vietinbankipay">vietinbankipay</van-radio>
								<van-radio name="Vietcombank">vietcombank</van-radio>
								<van-radio name="Vtpay">vtpay</van-radio>

								<van-radio name="Tpbank">tpbank</van-radio>
								<van-radio name="Techcombank">techcombank</van-radio>
								<van-radio name="Pvbank">pvbank</van-radio>
								<van-radio name="Vibbank">vibbank</van-radio>
								<van-radio name="Mbbank">mbbank</van-radio>
								<van-radio name="Bidv">bidv</van-radio>
								<!-- <van-radio name="2">OpplePay</van-radio> -->
							</van-radio-group>
						</div>
						<div style="margin: 16px">
							<van-button round block type="info" native-type="submit">下一步</van-button>
						</div>
					</div>
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from "vue";
	import {
		Form
	} from "vant";
	import {
		Field
	} from "vant";
	import {
		RadioGroup,
		Radio
	} from "vant";
	Vue.use(Form).use(Field).use(RadioGroup).use(Radio);
	export default {
		data() {
			return {
				balance: 0,
				pay_way: "Mexicopay",
				win_money: 0,
				money: "",
				personalreport: {},
			};
		},
		mounted() {
			this.balance = this.$route.params.balance;
		},
		methods: {
			back() {
				return window.history.back();
			},

			//拉起支付
			onSubmit(values) {
				const money = values.money;
				if (money <= 0) {
					this.$toast(this.$t("reservation.money_err"));
					return;
				}
				this.$http({
					method: "post",
					data: {
						pay_way: this.pay_way,
						money: money,
					},
					url: "recharge",
				}).then((res) => {
					console.log(res);
					if (res.code === 200) {
						window.location.href = res.data.pay_url;
						//   this.personalreport = res.data;
						//   this.win_money =
						//     this.personalreport.win_money - this.personalreport.play_money;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},

			getPersonalreport() {
				this.$http({
					method: "get",
					url: "user_get_personalreport",
				}).then((res) => {
					if (res.code === 200) {
						this.personalreport = res.data;
						this.win_money =
							this.personalreport.win_money - this.personalreport.play_money;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				});
			},
		},
		created() {
			if (!localStorage.getItem("token")) {
				this.$router.push({
					path: "/Login"
				});
			} else {
				this.getPersonalreport();
			}
		},
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.container .header {
		background: linear-gradient(270deg, #e6c3a1, #7e5678);
	}

	.recharge {
		padding: 10px 30px;
	}

	.van-cell {
		line-height: 65px !important;
	}

	.van-button {
		height: 87px !important;
	}

	.van-button__text {
		color: #fff !important;
	}

	/deep/.van-radio__icon {
		font-size: 30px !important;
	}

	/deep/.van-radio__label {
		margin-left: 25px !important;
		font-size: 35px !important;
	}

	/deep/.van-radio {
		height: 65px !important;
	}

	.form-item {
		margin-top: 40px;
	}

	.form-item-title {
		font-size: 36px;
		font-weight: bold;
		color: #999;
		margin-bottom: 20px;
	}

	.recharge span {
		font-size: 4vw;
		color: #868686;
		font-weight: 500;
	}

	.container .header .info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 20px;
		padding-top: 10px;
		margin: auto;
	}

	.container .header .info .title {
		font-size: 25px;
		color: #e5e5e5;
	}

	.container .header .info .value {
		margin: 10px auto;
		color: #fff;
		font-size: 50px;
		border-bottom: 1px solid #fff;
	}

	.container .header .info .tip {
		font-size: 30px;
		color: #e5e5e5;
	}

	.container .content {
		flex: 1;
		background: #f2f2f5;
	}
</style>