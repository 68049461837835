import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import Fangpian from '../pages/game/fangpian.vue'/* 防骗 */
import Zhibojian from '../pages/video/zhibojian.vue'/* 直播间 */
import Livevideo from '../pages/video/livevideo.vue'/* 短视频 */
import Detail from '../pages/home/detail.vue'/* 首页详情 */
import Language from '../pages/mine/Language'/* 切换语言 */
import Recharge1 from '../pages/mine/Recharge1.vue'/* 充值1  重写*/ 
import pay from '../pages/mine/pay.vue'/* */ 
import rechargeUpload from '../pages/mine/rechargeUpload.vue'/* */ 
import rechargeSuccess  from '../pages/mine/rechargeSuccess'/*  */
import RechargeRecord  from '../pages/mine/RechargeRecord'/* 充值记录 */



Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: 'Sflirt' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: 'Sflirt' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: 'Sflirt' } },
    { path: '/List', name: 'list', component: List, meta: { title: 'Sflirt' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: 'Sflirt' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: 'Sflirt' } },
    { path: '/Video', name: 'video', component: Video, meta: { title: 'Sflirt' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: 'Sflirt' } },
    { path: '/Login', name: 'login', component: Login, meta: { title: 'Sflirt' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: 'Sflirt' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: 'Sflirt' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: 'Sflirt' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: 'Sflirt' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: 'Sflirt' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: 'Sflirt' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: 'Sflirt' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: 'Sflirt' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: 'Sflirt' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: 'Sflirt' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: 'Sflirt' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: 'Sflirt' } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: 'Sflirt' } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: 'Sflirt' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: 'Sflirt' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: 'Sflirt' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: 'Sflirt' } },
    { path: '/Fangpian', name: 'Fangpian', component: Fangpian, meta: { title: 'Sflirt' } },
	{ path: '/Zhibojian', name: 'zhibojian', component: Zhibojian, meta: { title: 'Sflirt' } },
	{ path: '/Livevideo', name: 'livevideo', component: Livevideo, meta: { title: 'Sflirt' } },
	{ path: '/Detail', name: 'detail', component: Detail, meta: { title: 'Sflirt' } },
    { path: '/Language', name: 'Language', component: Language, meta: { title: 'Sflirt' } },
    {path:'/Recharge1',name:'Recharge1',component:Recharge1,meta:{title:'Sflirt'}},
    {path:'/pay',name:'pay',component:pay,meta:{title:'Sflirt'}},
    {path:'/rechargeUpload',name:'rechargeUpload',component:rechargeUpload,meta:{title:'Sflirt'}},
    {path:'/rechargeSuccess',name:'rechargeSuccess',component:rechargeSuccess,meta:{title:'Sflirt'}},
    {path:'/RechargeRecord',name:'RechargeRecord',component:RechargeRecord,meta:{title:'Sflirt'}},
];

//生成路由实例
/* const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    console.log(to);
    if (
        to.name !== 'home' &&
        to.name !== 'login' &&
        to.name !== 'register' &&
        to.name !== 'Language' && // Add this condition for the language route
        !localStorage.getItem('token')
    ) {
        router.push('login');
        return;
    }
    if (to.matched[0].meta) {
        document.title = to.matched[0].meta.title;
    }
    next();
}); */
//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router