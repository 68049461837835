<template>
    <div class="bg-container page">
        <!-- <img class="bg-img" src="img/login/login-bg.png"> -->
        <div class="bg-wrapper">
            <div class="login">
                <van-nav-bar class="nav-bar">
                    <template #left>
                        <van-icon name="arrow-left" color="#fff" @click="$router.push({ path: '/home' })" />
                    </template>
                    <template #right>
                        <div class="language" @click="navigateToLanguage">
                            <img :src="currentImage" />
                            <span>{{ currentLanguage }}</span>
                        </div>
                    </template>
                </van-nav-bar>
                <div class="wrapper">
                    <div class="logo-container">
                        <div class="logo-wrapper">
                            <!-- <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:'/img/null.png'"> -->
                        </div>
                    </div>
                    <div class="title">{{$t('登录')}}</div>
                    <div class="loginForm">
                        <van-field v-model="username" clearable input-align="center" class="input"
                            :placeholder="$t('请输入用户名')" />
                        <van-field v-model="password" :type="passwordType" input-align="center" class="input"
                            :placeholder="$t('请输入密码')">
                            <template slot="right-icon">
                                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
                                    @click="switchPasswordType" />
                            </template>
                        </van-field>
                        <div class="reset-text" style="display: none">
                            <span>{{$t('忘记密码?')}}</span>
                        </div>
                        <div @click="toRegister()" class="register-text">
                            <span>{{$t('没有账户?马上注册')}}</span>
                        </div>
                        <van-button class="login-btn" type="primary" size="normal"
                            @click="doLogin()">{{$t('登录')}}</van-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: "inputValue",
            event: "input",
        },
        props: {
            inputValue: {
                type: String,
                default: "",
            },
        },
        computed: {
            currentLanguage() {
                const languages = {
                    'en_us': 'English',
                    'zh_cn': '简体中文',
                    'zh_hk': '繁體中文'
                };
                return languages[this.lang] || '繁體中文'; // 默认为English
            },
            currentImage() {
                return require(`../images/language/${this.lang}.png`);
            }
        },
        data() {
            return {
                username: "",
                lang: localStorage.getItem('lang') || 'en_us',
                password: this.inputValue,
                passwordType: "password",
            };
        },
        methods: {
            goHome() {
                console.log('Icon clicked');
                this.$router.push({
                    path: '/home'
                });
            },
            navigateToLanguage() {
                if (this.$route.path !== '/language') {
                    this.$router.push('/language'); // 导航到语言选择路由
                }
            },
            switchPasswordType() {
                this.passwordType =
                    this.passwordType === "password" ? "text" : "password";
            },
            back() {
                return window.history.back();
            },
            toRegister() {
                this.$router.push("Register");
            },
            doLogin() {
                if (
                    this.username === "" ||
                    this.username === null ||
                    this.username === undefined
                ) {
                    this.$toast(this.$t("请输入用户名"));
                    return false;
                }
                if (
                    this.password === "" ||
                    this.password === null ||
                    this.password === undefined
                ) {
                    this.$toast(this.$t("请输入密码"));
                    return false;
                }
                this.$http({
                    method: "post",
                    url: "member_login",
                    data: {
                        username: this.username,
                        password: this.password,
                    },
                }).then((res) => {
                    if (res.code === 200) {
                        this.$toast.success(res.msg);
                        localStorage.setItem("token", res.data.id);
                        console.log("Received Token:", res.data.id); // 输出 Token 值
                        this.$router.push("Mine");
                    } else {
                        this.$toast(res.msg);
                    }
                });
            },
        },
        created() {
            if (localStorage.getItem("token")) {
                return window.history.back();
            }
        },
    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";

    .language {
        position: absolute;
        top: 18px;
        right: 0;
        height: 60px;
        color: #fff;
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            margin: 0 3px;
        }
    }

    ::v-deep .van-nav-bar__right {
        width: 50%;
    }

    .login {
        height: 100%;
    }

    .bg-container .bg-wrapper .login .nav-bar {
        background: 0 0;
    }

    .login .wrapper {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .logo-container {
        margin: 0 auto;
        width: 45%;
    }

    .logo-container .logo-wrapper {
        position: relative;
        padding-bottom: 62.5%;
    }

    .logo-container .logo-wrapper .logo-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .login .wrapper .title {
        line-height: 100px;
        text-align: center;
        font-size: 45px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 5px;
    }

    .login .wrapper .loginForm {
        padding: 60px;
    }

    .login .wrapper .loginForm .input {
        padding: 10px 20px;
        margin-top: 40px;
        border-radius: 10px;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        color: #4e4e4e;
    }

    ::v-deep .van-field__right-icon .van-icon {
        font-size: 50px;
    }

    ::v-deep .van-icon {
        font-size: 50px;
    }

    .login .wrapper .loginForm .reset-text {
        margin: 30px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .login .wrapper .loginForm .reset-text span {
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        line-height: 15px;
    }

    .login .wrapper .loginForm .register-text {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login .wrapper .loginForm .register-text span {
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        line-height: 20px;
    }

    // .login .wrapper .loginForm .active {
    // }
    .login .wrapper .loginForm .login-btn {
        margin-top: 85px;
        width: 100%;
        height: 100px;
        border-radius: 10px;
        color: #fff;
        /* background-color: #7e5678; */
        background: linear-gradient(#fde3ca, #e7b486);
        font-size: 40px;
        font-weight: bolder;
        border: none;
    }
</style>