<template>
    <van-tabbar v-if="show" v-model="active" active-color="#7e5678" :border="true" inactive-color="#979799">
            <van-tabbar-item v-for="(k, key) in items" :key="key" :to="k.router || 'javascript:void(0);'"
                @click="k.action && executeAction(k.action)">
            <span>{{ k.title }}</span>
            <template #icon="props">
                <div :class="{ 'kefu-wrapper': key === 2 }">
                    <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title"
                        :class="{ 'kefu-icon': key === 2 }" />
                </div>
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                active: 0
            };
        },
        computed: {
            items() {
                return [{
                        router: "/Home",
                        title: this.$t("首页"),
                        icon: {
                            active: "/img/footer/active_home.png",
                            noactive: "/img/footer/normal_home.png",
                        },
                    },
                    {
                        router: "/Game",
                        title: this.$t("预约"),
                        icon: {
                            active: "/img/footer/active_live.png",
                            noactive: "/img/footer/normal_live.png",
                        },
                    },
                    {
                        action: "toServicePage", // 代替router，你可以使用action来指定一个方法
                        title: this.$t("在线客服"),
                        icon: {
                            active: "/img/mine/kefu.png", // 激活状态的图标路径
                            noactive: "/img/mine/kefu.png", // 非激活状态的图标路径
                        },
                    },
                    {
                        router: "/Zhibojian",
                        title: this.$t("直播间"),
                        icon: {
                            active: "/img/footer/active_welfare.png",
                            noactive: "/img/footer/normal_welfare.png",
                        },
                    },
                    {
                        router: "/Mine",
                        title: this.$t("我的"),
                        icon: {
                            active: "/img/footer/active_mine.png",
                            noactive: "/img/footer/normal_mine.png",
                        },
                    },
                ];
            }
        },
        methods: {
            executeAction(action) {
                if (action && typeof this[action] === 'function') {
                    this[action]();
                }
            },
            toServicePage() {
                const service = this.$store.getters.getBaseInfo;
                if (service.iskefu == 1) {
                    window.location.href = service.kefu;
                }
            },
        },
        watch: {
            $route(to) {
                switch (to.name) {
                    case 'home':
                        this.active = 0;
                        this.show = true;
                        break;
                    case 'game':
                        this.active = 1;
                        this.show = true;
                        break;
                    case 'kefu':
                        this.active = 2;
                        this.show = true;
                        break;
                    case 'zhibojian':
                        this.active = 3;
                        this.show = true;
                        break;
                    case 'mine':
                        this.active = 4;
                        this.show = true;
                        break;
                    default:
                        this.show = false;
                        break;
                }
            }
        },
        created() {
            switch (this.$route.name) {
                case 'home':
                    this.active = 0;
                    this.show = true;
                    break;
                case 'game':
                    this.active = 1;
                    this.show = true;
                    break;
                case 'kefu':
                    this.active = 2;
                    this.show = true;
                    break;
                case 'zhibojian':
                    this.active = 3;
                    this.show = true;
                    break;
                case 'mine':
                    this.active = 4;
                    this.show = true;
                    break;
                default:
                    this.show = false;
                    break;
            }
        }
    };
</script>


<style lang="less" scoped>
    @tabbar-height: 110px;
    @tabbar-img: 50px;

    .van-tabbar {
        height: @tabbar-height;
        background-color: #1f1c1f !important;
    }

    .van-tabbar-item--active {
        background-color: #1f1c1f !important;
    }

    .van-tabbar-item__icon img {
        height: @tabbar-img;
    }

    .van-tabbar-item {
        font-size: 26px;
    }

    .kefu-wrapper {
        margin-top: -50px;
    }

    .kefu-icon {
        width: 100px;
        height: 100px !important;
        margin-top: -20px;

    }

    .tui {
        width: 4rem;
        margin-top: -10.333vw;
        background-color: #f3c7a2;
        border-radius: 50%;
        border: 10px solid #f3c7a2;
        z-index: 10;
    }

    [class*="van-hairline"]::after {
        border: none !important;
    }
</style>