<template>
    <div class="mine page">
        <div class="page-bg"></div>
        <div class="wrapper">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <div class="header">
                    <div class="user-wrapper" @click="doLogin()">
                        <van-image round class="user_img" :src="this.userInfo.header_img">
                            <template v-slot:loading>
                                <van-loading type="spinner" />
                            </template>
                        </van-image>
                        <div class="login-content">
                            <p class="login-btn">{{$t('用户')}}:{{ this.userInfo.username }}</p>
                            <p class="login-btn">{{$t('余额')}}:{{ this.userInfo.money }}</p>
                        </div>
                        <van-icon class="icon" name="arrow" />
                    </div>
                </div>
                <div class="content">
                    <div class="menu more_features_3">
                        <div class="menu-item" @click="doWithdrawal()" style="border: 0;">
                            <van-image class="menu-item-icon" src="img/mine/yh.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('提现')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                    </div>
                    <div class="menu more_features_3">
                        <div class="menu-item" @click="$router.push({ path: '/GameRecord' })">
                            <van-image class="menu-item-icon" src="img/mine/ds.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('打赏记录')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" style="display: none" @click="exit()">
                            <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('账户明细')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" @click="doPay()">
                            <van-image class="menu-item-icon" src="img/mine/cz.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('充值')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" @click="WithdrawRecord()">
                            <van-image class="menu-item-icon" src="img/mine/ds.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('兑换记录')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" @click="$router.push({ path: '/RechargeRecord' })" style="border: 0;">
                            <van-image class="menu-item-icon" src="img/mine/ds.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('充值记录')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div :style="{ marginTop: menu_top + 'px' }" class="menu">
                        <div class="menu-item" @click="$router.push({ path: '/Personalreport' })">
                            <van-image class="menu-item-icon" src="img/mine/jl.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('个人报表')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        
                        <div class="menu-item" @click="toNotice()">
                            <van-image class="menu-item-icon" src="img/mine/xx.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('会员消息')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" @click="Setbank()">
                            <van-image class="menu-item-icon" src="img/mine/yh.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t("银行卡管理")}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                        <div class="menu-item" @click="$router.push({ path: '/Setting' })">
                            <van-image class="menu-item-icon" src="img/mine/sz.png">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span class="menu-item-label">{{$t('设置')}}</span>
                            <van-icon class="icon" name="arrow" />
                        </div>
                    </div>
                </div>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userInfo: {},
                menu_top: 40,
                isLoading: false
            };
        },
        methods: {
            refresh() {
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                    if (localStorage.getItem('token')) {
                        this.$toast(this.$t('刷新成功'));
                    } else {
                        this.$router.push({
                            path: '/Login'
                        });
                    }
                }, 500);
            },
            exit() {
                this.$toast(this.$t('请完成任务单后进入'));
            },
            showSetting() {
                if (localStorage.getItem('token')) {
                    this.$router.push({
                        path: '/Setting'
                    });
                } else {
                    this.$router.push({
                        path: '/Login'
                    });
                }
            },
            Setbank() {
                this.$router.push({
                    path: '/Setbank'
                })
            },
            toNotice() {
                if (localStorage.getItem('token')) {
                    this.$router.push({
                        path: '/Notice'
                    });
                } else {
                    this.$router.push({
                        path: '/Login'
                    });
                }
            },
            onRefresh() {
                setTimeout(() => {
                    this.isLoading = false;
                    if (localStorage.getItem('token')) {
                        this.getUserInfo();
                        this.$toast(this.$t('刷新成功'));
                    } else {
                        this.$router.push({
                            path: '/Login'
                        });
                    }
                }, 500);
            },
            doLogin() {
                if (localStorage.getItem('token')) {
                    this.$router.push({
                        path: '/Infomation'
                    });
                } else {
                    this.$router.push({
                        path: '/Login'
                    });
                }
            },
            WithdrawRecord() {
                this.$router.push({
                    path: '/WithdrawRecord'
                })
            },
            doPay() {
                this.$router.push({
                    path: '/pay'
                })
            },
            doWithdrawal() {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.data.is_bank) {
                        this.$router.push('withdraw');
                    } else {
                        this.$router.push('Setbank');
                        this.$toast.fail(this.$t('请设置收款卡'));
                    }
                });
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                        console.log(res.data)
                        if (res.data.header_img == '') {
                            res.data.header_img = '/img/mine/avatar.png'
                        }
                        this.userInfo = res.data;
                        this.menu_top = 15;
                        if (this.userInfo.status !== 1) {
                            this.$toast(this.$t('账号下线'));
                            localStorage.clear();
                            this.$router.push({
                                path: '/Login'
                            });
                        }
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                });
            }
        },
        created() {
            if (localStorage.getItem('token')) {
                this.getUserInfo();
                this.userInfo.header_img = 'img/mine/avatar.png';
            } else {
                this.userInfo.username = this.$t('登录/注册');
                this.userInfo.ip = this.$t('登录可享受更多服务');
                this.userInfo.header_img = 'img/mine/avatar.png';
            }
        }
    };
</script>

<style scoped>
    .page {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        background-color: #16151b;
    }

    .mine {
        position: relative;
        bottom: 10px;
        background: #16151b;
    }

    .mine .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .nav-bar {
        background-color: #212121;
        /* background: linear-gradient(
      90deg,#7e5678,#e6c3a1); */
    }

    .mine .header {
        padding: 70px 0 40px;
        background-color: #212121;
    }

    ::v-deep .van-nav-bar__content {
        height: 100px;
    }

    ::v-deep .van-hairline--bottom::after {
        border-bottom-width: 0px;
    }

    .mine .header .van-nav-bar .van-icon {
        font-size: 45px;
    }

    .mine .header .user-wrapper {
        display: flex;
        align-items: center;
        margin: 0px 40px 0px 40px;
    }

    .mine .header .user-wrapper .icon {
        color: #868686;
    }

    .mine .user_img {
        height: 100px;
        width: 100px;
    }

    ::v-deep .van-loading__spinner {
        height: 50px;
        width: 50px;
    }

    ::v-deep .van-image__error-icon {
        font-size: 70px;
    }

    .mine .header .user-wrapper .login-content {
        flex: 1;
        margin-left: 30px;
    }

    .mine .header .user-wrapper .login-content .login-btn {
        font-size: 26px;
        line-height: 3px;
        color: #fff;
    }

    .mine .header .user-wrapper .login-content .login-label {
        margin-top: -13px;
        font-size: 28px;
        color: hsla(0, 0%, 100%, 0.6);
    }



    .mine .content {
        position: relative;
    }

    .mine .content .menu {
        margin-top: 23px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #212121;
        margin-bottom: 0.26667rem;
    }

    .mine .content .menu .menu-item {
        color: #868686;
        width: calc(100% - 0.53333rem) !important;
        height: 90px !important;
        justify-content: space-between !important;
        padding: 0 3px;
        border-bottom: .02667rem solid hsla(0,0%,80%,.3);
    }

    .mine .content .menu-item .menu-item-icon {
        width: 30px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
        color: #ffffff;
        font-size: 35px;
    }

    .mine .wrapper .content .finance {
        position: absolute;
        display: flex;
        align-items: center;
        top: -55px;
        left: 30px;
        right: 30px;
        height: 120px;
        background-color: #212121;
        border-radius: 15px;
        box-shadow: 0 1.5px 1px 0 #e4e4e7;
    }

    .mine .wrapper .content .finance .line {
        width: 3px;
        height: 40px;
        background-color: #ccc;
    }

    .mine .wrapper .content .finance .finance-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .mine .wrapper .content .finance .finance-item .text {
        margin-left: 30px;
        font-size: 30px;
        color: #fff;
        font-weight: 500;
    }

    .mine .wrapper .content .finance .finance-item .icon {
        font-size: 40px;
        color: #868686;
    }

    .mine .wrapper .content .menu {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #212121;
    }

    .mine .wrapper .content .menu .menu-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        height: 130px;
    }

    .mine .wrapper .content .menu .menu-item .menu-item-label {
        flex: 1;
        display: block;
        font-size: 26px;
        color: #fff;
        font-weight: 500;
        text-align: left;
    }

    .mine .wrapper .content .menu .menu-item .menu-item-icon {
        margin: 25px;
        width: 40px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .mine .wrapper .content .wallet {
        margin-top: 80px;
        padding: 0 30px;
        background-color: #212121;
        border-radius: 15px;
        box-shadow: 0 1.5px 1px 0 #e4e4e7;
    }

    .mine .wrapper .content .wallet .part-1 {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .mine .wrapper .content .wallet .font-primary-color {
        color: #fff;
    }

    .font-gray {
        color: #fff;
    }

    .mine .wrapper .content .wallet .part-2 {
        display: flex;
        align-items: center;
        height: 150px;
    }

    .mine .wrapper .content .wallet .part-2 .balance {
        flex: 1;
        font-size: 60px;
        color: #e6b98a;
        font-weight: 700;
    }

    .mine .wrapper .content .wallet .van-hairline--bottom::after {
        border-bottom-width: 3px;
    }

    .mine .wrapper .content .wallet .part-2 .refresh-btn {
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 30px;
        border-radius: 50%;
        color: #ffffff;
        background-color: #e6c3a1;
    }
</style>