<template>
    <div class="container page">
        <div class="header">
            <van-nav-bar :title="$t('充值')" class="nav-bar">
                <template #left>
                    <van-icon name="arrow-left" color="#fff" @click="back()" />
                </template>
            </van-nav-bar>
        </div>
        <div class="main">
            <div class="payMoney">
                <div class="label"><span style="font-size:14px">{{$t('存款积分')}}:</span><span style="font-size:14px">{{$t('请输入金额')}}</span>
                </div>
                <div class="money">
                    <div class="van-cell van-field fieldMoney">
                        <div class="van-cell__value van-cell__value--alone van-field__value">
                            <div class="van-field__body">
                                <input type="tel" inputmode="numeric"
                                    class="van-field__control van-field__control--center" v-model="num">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="moneyNumber">
                    <button :class="{ isActive: activeButton === 1 }" @click="setActiveButton(1,100)">100</button>
                    <button :class="{ isActive: activeButton === 2 }" @click="setActiveButton(2,500)"
                        class="">500</button>
                    <button :class="{ isActive: activeButton === 3 }" @click="setActiveButton(3,1000)"
                        class="">1000</button>
                    <button :class="{ isActive: activeButton === 4 }" @click="setActiveButton(3,1500)"
                        class="">1500</button>
                    <button :class="{ isActive: activeButton === 5 }" @click="setActiveButton(3,2000)"
                        class="">2000</button>
                    <button :class="{ isActive: activeButton === 6 }" @click="setActiveButton(3,3000)"
                        class="">3000</button>
                    <button :class="{ isActive: activeButton === 7 }" @click="setActiveButton(3,5000)"
                        class="">5000</button>
                    <button :class="{ isActive: activeButton === 8 }" @click="setActiveButton(3,10000)"
                        class="">10000</button>
                </div>
                <div class="service"><span>{{$t('如有问题请联系在线客服')}}</span></div><button type="button"
                    class="van-button van-button--default van-button--normal sub-btn active" @click="submit">
                    <div class="van-button__content"><span class="van-button__text">{{$t('提交')}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    // import axios from 'axios';
    export default {
        data() {
            return {
                activeButton: 0,
                num: ''
            };
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                });
            } 
        },
        methods: {
            gokefu() {
                this.$router.push({
                    name: 'ServiceOnline',
                })
            },
            back() {
                return window.history.back();
            },
            setActiveButton(button, num) {
                this.activeButton = button;
                this.num = num
            },
            submit() {
                if (this.num == '' || this.num == 0) return;
				this.$http({
                    method: "post",
                    data: {
                        img: '',
                        money: this.num
                    },
                    url: "recharge1"
                }).then((res) => {
                    if (res.code === 200) {
						window.location.href = res.data.payUrl;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                });
            }

        }

    }
</script>

<style lang="scss" scoped>
    .van-field__body {}

    .main {
        padding: 30px;
        color: #fff;
        height: calc(100% - 50px);
        overflow-y: auto;
        background-color: #212121;

        .payType {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid #f2f2f5;

            .payname {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 42px;
                    margin-right: 14px;
                }

                .name {
                    color: #fff;
                }
            }

            .description {
                margin-top: 21px;
                color: #fff;
            }
        }

        .payMoney {
            display: flex;
            flex-direction: column;

            .label {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 60px 0;
                font-size: 14px;
                color: #fff;
            }

            .money {
                font-size: 40px;
                width: 100%;
                color: #fff;
                border-bottom: 1px solid #f2f2f5;
                padding-bottom: 22px;
                display: flex;
                align-items: center;

                .fieldMoney {
                    width: 90% !important;
                    margin: 0 auto;
                    border-radius: 20px;
                    height: 60px;
                    line-height: 45px;
                    font-size: 32px;
                }
            }

            .remark {
                padding: 30px 0;
                display: flex;
                flex-direction: column;
                color: #fff;
            }

            .moneyNumber {
                margin-top: 50px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                button {
                    margin-left: 10px;
                    margin-bottom: 20px;
                    min-width: 160px;
                    height: 80px;
                    outline: 0;
                    border: 1px solid #fff;
                    border-radius: 10px;
                    color: #000;
                    background: #fff;
                    font-size: 12px;
                }

                .isActive {
                    border: 0 !important;
                    color: #fff !important;
                    background-color: #fff !important;
                }
            }

            .balance {
                font-size: 36px;
                color: #fff;
                margin: 22px 0;
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    color: #ee0e0e
                }
            }

            .service {
                text-align: center;
                margin-top: 22px;
                font-size: 26px;
                color: #fff;

                a {
                    font-size: 26px;
                    color: #ee0e0e;
                    text-decoration: underline;
                }
            }

            .sub-btn {
                margin-top: 16px;
                width: 100%;
                height: 100px;
                line-height: 50px;
                border-radius: 60px;
                background-color: #cacacc;
                color: #fff;
                font-size: 36px;
                font-weight: bolder;
                border: none;
                color: #fff;
                background: linear-gradient(#fde3ca, #e7b486);
            }

        }
    }
</style>